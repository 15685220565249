@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "sf-pro-display";
  src: url("./assets/Fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF");
}

@font-face {
  font-family: "DIN-Condensed";
  src: url("./assets/Fonts/din-condensed/DIN-Condensed-Bold.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=DIN+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;900&display=swap");

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/Fonts/sf-pro-display/SFPRODISPLAYREGULAR.OTF");
  font-weight: 400;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/Fonts/sf-pro-display/SFPRODISPLAYMEDIUM.OTF");
  font-weight: 500;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/Fonts/sf-pro-display/SFPRODISPLAYSEMIBOLDITALIC.OTF");
  font-weight: 600;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/Fonts/sf-pro-display/SFPRODISPLAYBOLD.OTF");
  font-weight: 700;
}



* {
  box-sizing: border-box;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #C2C2C2;
  border-radius: 0 5px 5px 0;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00c0f3;
  border-radius: 0 5px 5px 0;
}

.custom-scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* This applies to the whole page */
*::-webkit-scrollbar {
  width: 6px;
  height: 7px;

  /* Adjust scrollbar width */
}

*::-webkit-scrollbar-track {
  background: white;
  /* Adjust scrollbar track color */
}

*::-webkit-scrollbar-thumb {
  background: #00C0F3;
  /* Adjust scrollbar handle color */
}

*::-webkit-scrollbar-thumb:hover {
  background: #00C0F3;
  /* Adjust scrollbar handle hover color */
}